import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";

export default class VoteChart extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  async model(params) {
    return params.vote_id;
  }
}
