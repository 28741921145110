import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency-decorators";
import { action, computed, observes } from "@ember/object";
import Evented from "@ember/object/evented";

export default class Novote extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    novote: Novote;
  }
}
