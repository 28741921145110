import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency-decorators";
import gql from "graphql-tag";
import { USER_FRAGMENT } from "event-components/gql/fragments";

const query = gql`
query users($where: UserWhereInput!) {
  users(where: $where) {
    ${USER_FRAGMENT}
  }
}
`;
export default class Nouser extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService;
  @service("amplify") amplify;

  email: string = "";
  firstname: string = "";
  lastname: string = "";
  errorMessage: string = "";

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  @task
  *resendInviteEmail(email: string) {
    console.log(`resend email to: ${this.email}`);
    const variables = {
      where: {
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        client: {
          id: this.get("amplify.currentClient.id"),
        },
      },
    };
    let users = yield this.apollo.query(
      { query: query, variables: variables, fetchPolicy: "network-only" },
      "users"
    );
    if (users.length > 0) {
      console.log(users);
    } else {
      this.set("errorMessage", "Wir haben diesen Benutzer nicht gefunden!");
      return;
    }
    yield fetch(
      "https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject:
            "Ihr persönlicher Link zur Registrierung | Zwischenbilanzkonferenz Strategiedialog Automobilwirtschaft BW ",
          content: `
          Sehr geehrte Damen und Herren,
 

          wie gewünscht erhalten Sie Ihren persönlichen <a href="https://${window.location.hostname}/#/i/${users.firstObject.invitecode}/${users.firstObject.username}">Link</a> zur Registrierung.  

      
          
          Bei allen Fragen zur Anmeldung steht Ihnen Frau Maileen Zehnder, Agentur u-motions GmbH, unter registrierung@sda2020-partner.de oder telefonisch dienstags und donnerstags von 10:00 bis 12:00 Uhr unter 0721 915778 733 gerne zur Verfügung.
          
           
          
          Herzliche Grüße,
          
           
          
          Organisationsteam SDA 2020
          
           
          
          E-Mail:  registrierung@sda2020-partner.de
          
          Tel.:       0721 915 778 733 (Dienstag und Donnerstag, 10:00 - 12:00 Uhr)
        `.replace(/\n/g, "<br />"),
        }),
      }
    ).then((response) => response.json());
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    nouser: Nouser;
  }
}
