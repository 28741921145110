import Component from '@ember/component';
import { tagName, attribute, } from '@ember-decorators/component';
import { inject as service} from '@ember/service';
import { computed, action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { run } from '@ember/runloop';
import RecognizerMixin from 'ember-gestures/mixins/recognizers';
export default class ImageModal extends Component.extend({
  ...RecognizerMixin
}) {

  recognizers = 'pinch'

  class: string = "fade";

  @service('amplify') amplify;

  mapHeight?: number = null;

  @action
  pinchAction(e) {
    console.log(e)
  }

  @computed('file')
  get imagepath() {
    if (isEmpty(this.file)) {
      console.log("empty map");
      return;
    }
    return this.get('amplify').Storage.get(this.file.key, { level: 'public' }); 
  }

  @action
  didInsertImage() {
    if (this.onDidInsertElement) {
      this.onDidInsertElement();
    }
  }

  @action
  click() {
    this.onClose();
  }
};
