import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import amplify from 'event-app/initializers/amplify';
export default class Nouser extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service amplify;

  beforeModel() {
    this.transitionTo('landing');
  }
}
