import Route from '@ember/routing/route';

export default class Stream extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  beforeModel() {
    if (!this.get('amplify.isAuthenticated')) {
      this.transitionTo('landing');
      return;
    }
    const user = this.amplify.get('currentUser');
    const streamAppurl = this.amplify.get('currentTheme.data.streamAppurl');
    window.open(
      `${streamAppurl}/#/i/${user.invitecode}/${user.username}`,
      '_blank'
    );
    return this.transitionTo('index');
  }
}
