import Component from '@ember/component';
// @ts-ignore: Ignore import of compiled template
import layout from './template';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { computed } from '@ember/object';
import { observes } from '@ember-decorators/object';
import { getOwner } from '@ember/application';
import { next } from '@ember/runloop';

import gql from 'graphql-tag';

import { VOTE_FRAGMENT } from 'event-components/gql/fragments';

const query = gql`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${VOTE_FRAGMENT}
    }
  }
`;

export default class VoteChartInternal extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  layout = layout;

  @service() router;
  @service('amplify') amplify;
  @service('vote') voteService;
  @service('graphql') apolloService;
  @service('cookies') cookies;

  @observes('currentVote.id')
  onCurrentVoteChanged() {
    this.set('currentVote', this.currentVote.id);
  }

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  didInsertElement() {
    this.amplify.on('ws:updateVote', this, 'onVotesChanged');
  }
  async onVotesChanged(vote: any) {
    console.log(vote, 'reload');
    if (vote.node) {
      vote = vote.node;
    }
    if (vote.id !== this.get('model.vote.id')) {
      return;
    }
    console.log("VOTE", { ...this.get('model.vote'), ...vote })
    next(this, function () {
      this.set('model.vote', { ...this.get('model.vote'), ...vote });
    });
  }

  @observes('voteService.votes.[]')
  async loadModel() {
    let vote, votes;

    const client = this.amplify.get('currentClient');

    votes = this.get('voteService.votes');
    vote = votes.find((v:any)=>v.id==this.voteId);

 
    this.set('model', {
      vote,
      votes,
      user: client.user,
      client: client,
    });
  }

  @computed('model.vote.charttype')
  get chartoptions() {
    return {
      animation: {
        duration: 0,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 25,
          bottom: 0,
        },
      },
      plugins: {
        labels: [
          {
            render: 'label',
            position: 'outside',
            fontSize: this.get('model.vote.fontsize') ? this.get('model.vote.fontsize').replace('px', ''): 14,
            fontStyle: 'bold',
          },
          {
            render: function (args) {
              return ``;
            },
            fontSize: this.get('model.vote.fontsize') ? this.get('model.vote.fontsize').replace('px', ''): 14,
            fontStyle: 'bold',
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            //get the concerned dataset
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //calculate the total of this data set
            var total = dataset.data.reduce(function (
              previousValue,
              currentValue,
              currentIndex,
              array
            ) {
              return previousValue + currentValue;
            });
            //get the current items value
            var currentValue = dataset.data[tooltipItem.index];
            //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
            var percentage = Math.floor((currentValue / total) * 100 + 0.5);

            return percentage + '%';
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: true,
            },
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number

                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: false,
            },
          },
        ],
      },
    };
  }
  @task()
  *goBack(voteId) {
    this.get('router').transitionTo('vote', voteId);
  }
  @task()
  *reload(voteId) {
    let route = getOwner(this).lookup(`route:votechart`);
    return route.refresh();
  }

  @computed('currentAnswer', 'model.vote.votes.[]', 'voteService.votes.[]')
  get data() {
    try {
      if (!this.get('model.vote.votes')) {
        return null;
      }

      function getSum(total, num) {
        return total + Math.round(num);
      }
      const data = this.get('model.vote.votes').reduce((data, vote) => {
        let index = this.get('model.vote.values').indexOf(vote.vote);
        if (data[index] === undefined) {
          data[index] = 0;
        }
        data[index] = data[index] + 1;
        return data;
      }, []);
      return {
        labels: this.get('model.vote.values').map((v, index) => {
          let all = data.reduce(getSum, 0);
          if (data[index] === undefined) data[index] = 0;
          return `${v} (${Math.round((data[index] / all) * 100 * 100) / 100}%)`;
        }),
        datasets: [
          {
            data: data,
            borderSkipped: false,
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundColor: this.get('model.vote.colors').map((c: string) => {
              if (c.includes('#')) {
                return `${c}`;
              } else {
                return `#${c}`;
              }
            }),
          },
        ],
      };
    } catch (e) {
      console.error(e);
    }
  }

  didReceiveAttrs() {
    this.set('voteid', this.get('cookies').read(`vote-${this.votescope}`));
    this.loadModel();
  }

  @computed('model.vote.votes.[]', 'currentVote.vote.votes.[]')
  get currentAnswers() {
    if (this.get('model.vote.votes') == null) {
      return false;
    }
    return this.get('model.vote.votes').filterBy(
      'user',
      this.amplify.currentUser.id
    );
  }

  willDestroy() {
    this.get('voteService').off('vote:changed');
  }
}
