import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { className, classNames } from '@ember-decorators/component';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';

import gql from "graphql-tag";

@classNames('app-votescope-item')
export default class AppVotescopeItem extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  @service isMobile;
  @service amplify;
  @service router;


  @task
  *openScope(voteScope) {
      this.onScope(voteScope);
  }

};
