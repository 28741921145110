import Controller from "@ember/controller";
import { inject as service } from "@ember/service";
import { computed, action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { observes } from "@ember-decorators/object";
export default class Application extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("chat") chatService;

  queryParams = ["chat"];
  chat: string = null;
  chatrooms: object[] = [];

  currentChatroom?: any = null;
  nullValue = null;

  constructor() {
    super(...arguments);
    this.headerElement = document.getElementById("header-destination");
  }

  @computed("currentChatroom")
  get currentTargetRoute() {
    if (this.get("currentChatroom")) {
      return "chat";
    } else {
      return "index";
    }
  }

  @action
  unsetCurrentRoom() {
    if (this.get("currentChatroom") === null) {
      this.transitionToRoute("index");
    } else {
      this.set("currentChatroom", null);
      //this.set("chat", null);
      this.transitionToRoute("chat.private");
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module "@ember/controller" {
  interface Registry {
    application: Application;
  }
}
