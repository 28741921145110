import Route from '@ember/routing/route';
import {
  PROGRAM_FRAGMENT,
  AGENDA_FRAGMENT,
} from 'event-components/gql/fragments';
import gql from 'graphql-tag';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import moment from 'moment';
import amplify from 'event-app/initializers/amplify';
const query = gql`
  query program($where: ProgramItemWhereInput!) {
    programItems(orderBy: date_ASC, where: $where) {
      ${PROGRAM_FRAGMENT}
    }
  }
`;

const query2 = gql`
  query agenda {
    agendaItems {
      ${AGENDA_FRAGMENT}
    }
  }
`;

export default class Fragebogen extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('graphql') apolloService;

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  beforeModel(model: any) {
    if (
      this.get('amplify.currentUser') == null &&
      this.amplify.currentTheme.data.authmodules?.program === true
    ) {
      this.transitionTo('landing');
    }
  }
}
