import Route from '@ember/routing/route';
import { INFO_FRAGMENT } from 'event-components/gql/fragments';
import gql from 'graphql-tag';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import moment from 'moment';
const query = gql`
  query infoItems($where: InfoItemWhereInput!) {
    infoItems(where: $where) {
      ${INFO_FRAGMENT}
    }
  }
`;

export default class Info extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('graphql') apolloService;

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  beforeModel(model: any) {
    if (
      this.get('amplify.currentUser') == null &&
      this.amplify.currentTheme.data.authmodules?.info === true
    ) {
      this.transitionTo('landing');
    }
  }
  async model() {
    let client = await this.modelFor('application');
    let variables = {
      where: {
        client: {
          id: client.id,
        },
      },
    };
    let infoItems = await this.apollo.query(
      { query, variables, fetchPolicy: 'network-only' },
      'infoItems'
    );

    return RSVP.hash({
      infoItems: infoItems,
      client: client,
    });
  }
}
