import Component from '@ember/component';
import { classNames, className, tagName } from '@ember-decorators/component';
import { isEmpty } from '@ember/utils';


@tagName('nav')
@classNames('app-header')
export default class AppHeader extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  @className('sticky', '') sticky: boolean = false;

  didInsertElement() {
    window.addEventListener('scroll', this.onScroll.bind(this), false);
  }

  onScroll(event: Event) {
    let pageYOffset = window.pageYOffset;
    let headerHeight = document.getElementById('header-image').getBoundingClientRect().height;

    if (pageYOffset >= (headerHeight - 50)) {
      this.set('sticky', true);
      document.body.classList.add("sticky-nav");

    } else {
      this.set('sticky', false);
      document.body.classList.remove("sticky-nav");
    }
  }
};
