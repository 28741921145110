import Route from "@ember/routing/route";

export default class Extern extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  beforeModel() {
    const user = this.amplify.get("currentUser");
    const streamAppurl = this.amplify.get("currentTheme.data.streamAppurl");
    window.open(`https://sda2020.de/einfacher-rundgang`, "_blank");
    return this.transitionTo("index");
  }
}
