import Route from "@ember/routing/route";
import gql from "graphql-tag";
import { USER_FRAGMENT } from "event-components/gql/fragments";
import { v4 } from "ember-uuid";
import { inject as service } from "@ember/service";

const mutation = gql`
mutation createUser($data: UserCreateInput!) {
  createUser(data: $data) {
    ${USER_FRAGMENT}
  }
}
`;

export default class Anonym extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }

  async model() {
    const client = await this.modelFor("application");
    const uid = v4();
    const variables = {
      data: {
        username: uid,
        firstname: "Anonym",
        lastname: "Anonym",
        client: {
          connect: {
            id: client.id,
          },
        },
      },
    };
    let user = await this.get("apollo").mutate(
      { mutation, variables },
      "createUser"
    );
    window.localStorage.setItem("user", user.id);
    console.log(`Login of Anonym User ${user.id}`);
    await this.transitionTo("index");
    window.location.reload();
  }
}
