import { helper } from '@ember/component/helper';

export function answerlimit(params/*, hash*/) {


  const question = params[0];
  const answer: string = params[1];

  if(question.agendalimit > 0) {
    if(answer.toLowerCase().includes("max. ")) {
      return +answer.split("max. ").reverse()[0].split(" ")[0];
    } else {
      return question.agendalimit
    }
  } else {
    return 0;
  }
}

export default helper(answerlimit);
