import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action, computed } from '@ember/object';
import { observes } from '@ember-decorators/object';
import Evented from '@ember/object/evented';
export default class Vote extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  
  queryParams = ['chart'];
  
  chart = 0;

  currentVote: object = null;

  @observes('currentVote.id')
  onCurrentVoteChanged() {
    this.set('currentVote', this.currentVote.id);
  }

  @service('vote') voteService;

  constructor() {
    super(...arguments);

    this.get('voteService').on('vote:changed', this, this.onVotesChanged);
    
  }

  willDestroy() {
    this.get('voteService').off('vote:changed');
  }

  onVotesChanged(vote: any) {
    console.log(...arguments)
    if (vote.id === this.currentVote.id) {
      this.set('currentVote', vote);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'vote': Vote;
  }
}
