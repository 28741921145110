import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class Application extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('graphql') apolloService;

  @service() moment;
  @service('amplify') amplify;

  headTags?: object[];

  target: string = '';




  constructor() {
    super(...arguments);

    this.set('apollo', this.get('apolloService').createQueryManager());
  }

  async beforeModel(transition) {
    

    
    await this.amplify.initClient();
    await this.amplify.initSession();
    this.set('amplify.target', transition.to.localName);
    let firstVisit = localStorage.getItem('firstvisit');
    console.log(transition);
    if (
      firstVisit !== '1' &&
      transition.to.name == 'index' &&
      this.amplify.currentClient.loginmode !== 'ANONYM'
    ) {
      this.transitionTo('landing');
      return;
    }
    if (
      !this.get('amplify.isAuthenticated') &&
      this.amplify.currentClient.loginmode !== 'ANONYM' &&
      transition.to.name !== 'login'
    ) {
      this.transitionTo('landing');
      return;
    }
  }

  async model() {
    
    const Auth = this.amplify.Auth;
    await this.setHeadTags(
      this.amplify.currentClient,
      this.amplify.currentTheme
    );
    return this.amplify.currentClient;
  }

  async setHeadTags(model, theme) {
    let headTags = await this.amplify.setHeadTags(model, theme);
    console.log('headTags', headTags);
    this.set('headTags', headTags);
    return headTags;
  }
}
