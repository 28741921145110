import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { waitForProperty } from 'ember-concurrency';
import { getOwner } from '@ember/application';

import gql from 'graphql-tag';
import { USER_FRAGMENT } from 'event-components/gql/fragments';
import { get } from '@ember/object';

const query = gql`
query users($where: UserWhereInput!) {
  users(where: $where) {
    ${USER_FRAGMENT}
  }
}
`;
export default class Login extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('graphql') apolloService;
  @service('amplify') amplify;
  @service('router') router;

  @task
  *toLogin() {
    this.transitionToRoute('login');
  }
  @task
  *toRegister() {
    this.transitionToRoute('register');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    nouser: Nouser;
  }
}
