/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:38d6514f-5d26-4c58-b711-643bdddec366",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ZvLfGBf21",
    "aws_user_pools_web_client_id": "a1a8qj87t6v9e2ffgm1b886u0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NICKNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "u-motions-event-app-20210120104142-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d32qsrf9dp4nwx.cloudfront.net",
    "aws_mobile_analytics_app_id": "175993d9a6674411b179aa14e43b2a55",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "umotionsuserstodelete-dev",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "umotions-event-app40adae9a5a5d4113bb768d01d1744de7-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
