import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import gql from 'graphql-tag';

import { action } from '@ember/object';

export default class Fragebogen extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('graphql') apolloService;
  @service('amplify') amplify;

  headTags?: object[];

  constructor() {
    super(...arguments);
    this.set('apollo', this.get('apolloService').createQueryManager());
  }
  @action
  willTransition(transition) {
    console.log('Abort');

    /*if(document.getElementById('anmeldeform').checkValidity() === false) {
      transition.abort();
      this.get('notifications').info('Bitte füllen Sie die rot umrandeten Felder aus.');
      this.controller.set('isInvalid', true);
    }*/
  }

  async model() {
    const client = await this.modelFor('application');

    const { questions, answers } = await this.amplify.findAllQuestions(client);
    return {
      answers,
      questions: questions.filter((q: any) => {
        return q.hide === null || q.hide === false;
      }),
      user: client.user,
      client: client,
    };
  }
}
