import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import { USER_FRAGMENT } from "event-components/gql/fragments";
import gql from "graphql-tag";

const mutation = gql`
mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ${USER_FRAGMENT}
  }
}
`;

export default class RegisterActivate extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service("graphql") apolloService;
  @service("amplify") amplify;

  constructor() {
    super(...arguments);
    this.set("apollo", this.get("apolloService").createQueryManager());
  }
  async model(params) {
    const variables = {
      where: {
        id: params.email,
      },
      data: {
        doubleoptin: true,
        token: "",
        groups: {
          set: ['DEFAULT']
        }
      },
    };
    try {
      await this.apollo.mutate({ mutation, variables });
    } catch (e) {
      console.error(e);
    }
  }
}
