import Application from '@ember/application';

export function initialize(application: Application): void {
  application.inject('controller', 'amplify', 'service:amplify');
  application.inject('component', 'amplify', 'service:amplify');
  application.inject('route', 'amplify', 'service:amplify');
  application.inject('controller', 'intl', 'service:intl');
  application.inject('component', 'intl', 'service:intl');
  application.inject('route', 'intl', 'service:intl');
}

export default {
  initialize
};
