import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import gql from 'graphql-tag';
import { AGENDA_FRAGMENT } from 'event-components/gql/fragments';
import RSVP from 'rsvp';
import { isEmpty } from '@ember/utils';

const query = gql`
  query agenda($where: AgendaItemWhereInput!) {
    agendaItems(where: $where) {
      ${AGENDA_FRAGMENT}
    }
  }
`;
export default class Agenda extends Route.extend({
  // anything which *must* be merged to prototype here
}) {}
