import EmberRouter from '@ember/routing/router';
import config from 'event-app/config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,
});

Router.map(function () {
  this.route('fragebogen');
  this.route('wall');
  this.route('programm');
  this.route('init', {
    path: '/i/:user_id/:user_name',
  });
  this.route('map');
  this.route('meeting');
  this.route('novote', {
    path: '/novote',
  });
  this.route('vote', {
    path: '/vote/:vote_id',
  });
  this.route('votechart', {
    path: '/chart/:vote_id',
  });
  this.route('chart', {
    path: '/chartinternal/:vote_id',
  });
  this.route('agenda');

  this.route('support', function () {
    this.route('impressum');
    this.route('datenschutz');
  });
  this.route('nouser');
  this.route('anfahrt');

  this.route('chat', function () {
    this.route('profiles');
    this.route('pm');
    this.route('private');
  });
  this.route('login');
  this.route('landing');
  this.route('info');
  this.route('profil', function () {
    this.route('photo', {
      path: '/photo/avatar',
    });
  });
  this.route('anonym');
  this.route('register', function () {
    this.route('activate', {
      path: '/activate/:email/:token',
    });
  });
  this.route('impress');
  this.route('activetest');
  this.route('stream');
  this.route('streams');
  this.route('extern');
  this.route('videocall');
});

export default Router;
