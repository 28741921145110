import { helper } from '@ember/component/helper';

export function specialCounter(params/*, hash*/) {

  let temp = params[0].findBy('value', params[1].toString());
  if (!temp) {
    return 0;
  }
  return temp.count
}

export default helper(specialCounter);
